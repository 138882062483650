<template>
  <div class="content">
    <p style="text-align: center;font-size: 1.5rem;font-weight: bolder">码上办后台管理系统</p>
    <a-form>
      <a-form-item>
        <a-input size="large" v-model="form.mobile" placeholder="手机号">
          <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)"/>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input size="large" v-model="form.password" type="password" placeholder="密码">
          <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)"/>
        </a-input>
      </a-form-item>
      <a-form-item>
        <captcha v-model="form.captcha" ref="captcha"/>
      </a-form-item>
      <a-form-item>
        <a-button style="width:100%;text-align:center;font-size: 18px;" type="primary" @click="handleSubmit">
          登 录
        </a-button>
      </a-form-item>
    </a-form>
    <div style="text-align: center;font-style: italic;">
      码上办后台管理系统 ©2022
    </div>
  </div>
</template>

<script>
import img from '#/assets/images/logo.jpeg';
import Captcha from '#/components/Captcha/Captcha.vue'

export default {
  components: {Captcha},
  data() {
    return {
      form: {},
      img: img
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.axios.post('admin/user/login', this.form).then(async res => {
        // 调用ref的get方法
        const {token, user} = res.data
        localStorage.setItem('token', token)
        localStorage.setItem("user", JSON.stringify(user))
        await this.$store.dispatch("getInfo").then(_ => {
          //检查系统是否已经完成安装
          if (!this.$store.state.isInstalled) {
            localStorage.clear();
            this.$error({
              title: '错误',
              content: '系统尚未初始化，请联系管理员',
              duration: 2
            })
            return
          }
          if(this.$store.state.roleMenus.length === 0){
            this.$error({
              title:"错误",
              content:"尚未配置该用户的角色及权限，请联系管理员",
              duration:2
            })
            localStorage.clear();
            return
          }
          // this.$router.push({path: '/admin'}) //不能采用router.push，因为访问到首页时
          window.location.href = '/admin'
          // this.$router.push({path: '/admin'})
        }).finally(() => {
          this.$refs.captcha.get();
        })
      })
    },
  },
};
</script>
<style scoped lang="scss">
/*
水平垂直居中
*/

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0 10px 10px 0;
  height: 100%;
  width: 400px;
  background-color: white;
  padding: 20px;
}

</style>
